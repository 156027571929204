<template>
  <div class="all">
    <div class="tip">
      <div class="tu1" v-if="dateList.status == 2">
        <img src="../../../../public/images-order/222222ico_wctb.png" alt="" />
      </div>
      <div class="tu1" v-if="dateList.status == 1">
        <img src="../../../../public/images-order/ico_cw4444.png" alt="" />
      </div>
      <div class="zi1" v-if="dateList.status == 1">未支付</div>
      <div class="zi1" v-if="dateList.status == 2">支付成功</div>
    </div>
    <div class="tu2">
      <img :src="dateList.ed_img_url" alt="" />
    </div>
    <div class="kuai">
      <div class="zi2">
        {{ dateList.ed_name }}
      </div>
      <div class="zi3">
        <span>订单编号：{{ dateList.order_number }}</span>
        <span>付款用户：{{ dateList.uname }}</span>
      </div>
    </div>
    <div class="clear"></div>
    <div style="display: flex; justify-content: space-between">
      <div class="zi4">
        时间：
        {{ dateList.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
      </div>
      <div class="zi5">单位：元</div>
    </div>
    <div class="clear"></div>
    <table>
      <tr class="first">
        <td>售价</td>
        <td>实付金额</td>
      </tr>
      <tr>
        <td class="zi6">
          <span>
            {{ dateList.selling_price == 0 ? '免费' : dateList.selling_price }}
          </span>
        </td>
        <td class="zi7">
          <div>
            <span
              v-if="dateList.couponList && dateList.couponList.length > 0"
              class="mr10"
            >
              使用了优惠券
            </span>
            {{ dateList.actual_price }}

            <el-popover
              placement="bottom"
              width="250"
              trigger="hover"
              style="margin-left:3px"
              v-if="dateList.couponList && dateList.couponList.length > 0"
            >
              <template slot="reference">
                <div class="vm help" :class="icon2"></div>
              </template>
              <el-table
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="dateList.couponList"
              >
                <el-table-column
                  label="优惠券名称"
                  prop="name"
                ></el-table-column>
                <el-table-column
                  label="抵扣金额(元)"
                  prop="discount_amount"
                ></el-table-column>
              </el-table>
            </el-popover>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
// import fade from "@/components/FadeAnimation";

export default {
  name: 'detailData',
  data() {
    return {
      dateList: [],
    }
  },
  created() {
    this.getDate()
    // }
  },
  computed: {
    idvalue() {
      return this.$route.params.id
    },
  },
  methods: {
    getDate() {
      this.$http({
        url: '/electronicsData/seeOrder',
        data: {
          edo_id: this.idvalue,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.dateList = data
          }
        },
        error: error => {
          if (error.code == 204) {
            this.dateList = []
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__header-wrapper {
  line-height: 10px;
  height: 45px;
}
::v-deep .el-table__cell {
  padding: 7px 0 !important;
}
.help {
  width: 14px;
  height: 14px;
  cursor: pointer;
  background: url('~@ass/img/1.2.9/ico_wh@2x.png');
  background-size: 100%;
  &.icon2 {
    width: 8px;
    height: 13px;
    background: url('~@ass/img/1.2.9/ico_xljt.png');
    background-size: 100%;
  }
}
.all {
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 1);
}
.tip {
  width: 100%;
  padding-top: 21px;
  box-sizing: border-box;
  height: 80px;
  background: rgba(244, 245, 250, 1);
}
.tu1 {
  width: 38px;
  height: 38px;
  float: left;
  margin-left: 20px;
}
.el-button span {
  width: 99px;
  text-align: center;
  display: block;
}
.zi1 {
  width: 64px;
  height: 21px;
  font-size: 16px;
  float: left;
  margin-top: 7px;
  margin-left: 20px;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}
.tu2 {
  width: 143px;
  height: 80px;
  margin-top: 30px;
  float: left;
  background: rgba(110, 110, 110, 1);
  border-radius: 7px;
}
.tu2 img {
  width: 143px;
  height: 80px;
}
.zi2 {
  margin-top: 30px;

  height: 19px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.zi3 {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-top: 24px;
}
.zi3 span:first-child {
  margin-bottom: 6px;
  display: block;
}
.kuai {
  width: 258px;
  float: left;
  margin-left: 20px;
}
.clear {
  clear: both;
}
.zi4 {
  height: 16px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-top: 30px;
}
.zi5 {
  height: 16px;
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-top: 30px;
}
table {
  width: 100%;
  height: 145px;
  border-collapse: collapse;
  margin-top: 10px;
}
table tr {
  height: 48px;
}
table tr td {
  width: 50%;
  border: 1px solid rgba(227, 227, 227, 1);
  text-align: right;
  padding: 19px;
  box-sizing: border-box;
}
.first {
  background: rgba(244, 245, 250, 1);
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 19px;
}
.zi6 {
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
  line-height: 21px;
}
.zi7 {
  font-size: 16px;
  color: rgba(255, 53, 53, 1);
  line-height: 21px;
  width: 600px;
}
.zi7 span {
  font-size: 12px;
}
.zi8 {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  float: left;
  margin-top: 4px;
}
.zuofei {
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
  line-height: 20px;
  width: 110px;
  height: 42px;
  border: 0px;
  background: rgba(255, 53, 53, 1);
  border-radius: 4px;
  padding-top: 3px;
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  margin-bottom: 54px;
  margin-top: 40px;
}
</style>
